
import { defineComponent } from 'vue'
import PageAuth from '@/components/layout/PageAuth.vue'
import TmSpinner from '@/components/shared/TmSpinner.vue'

export default defineComponent({
  components: {
    TmSpinner,
    PageAuth,
  },
  props: {
    title: String,
    subtitle: String,
    loading: Boolean,
  },
})
